.footer {
    background: var(--color-footer);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.directions {
    position: absolute;
    color: #05274b;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    transition: background-color 0.3s;
    text-align: center; 
}

.directions:hover {
    background-color: #d30004;
    color: white;
}

.contact-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2rem;
    margin-bottom: 100px;
}

.contact-details-child {
    display: flex;
    align-items: center;
    margin: 1rem;
}

.contact-details-child p {
    color: #fff;
    margin-left: 1rem;
    font-size: 14px;
}

.footer-copyright p {
    color: #fff;
    font-size: 14px;
    transform: translateY(45px);
}

@media screen and (max-width: 490px) {
    .contact-details {
        align-items: center;
    }
}