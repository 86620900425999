.portfolio-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.portfolio-navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.portfolio-navbar-links p a {
    text-decoration: none;
    position: relative;
    color: #05034e;
    font-weight: bold;
    transition: color 0.3s, transform 0.3s;
}


.portfolio-navbar-links p a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #d30004;
    transform: translateX(-50%);
    transition: width 0.3s;
}


.portfolio-navbar-links p a:hover {
    color: #d30004;
}


.portfolio-navbar-links p a:hover::before {
    width: 100%;
}


.portfolio-navbar-links p a:active::before {
    width: 0;
}

.portfolio-navbar-links-logo {
    margin-right: 2rem;
}

.portfolio-navbar-links-logo img {
    width: 62px;
    height: 16px;
}

.portfolio-navbar-links-container {
    display: flex;
}

.portfolio-navbar-ctabtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.portfolio-navbar-links-container p,
.portfolio-navbar-ctabtn p,
.portfolio-navbar-menu-container p {
    color: #05274b;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.portfolio-navbar-ctabtn a,
.portfolio-navbar-menu-ctabtn {
    padding: 0.5rem 1rem;
    color: #05034e;
    background: #d30004;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.portfolio-navbar-menu {
    display: none;
    margin-left: 1rem;
    position: relative;
}

.portfolio-navbar-menu svg {
    cursor: pointer;
}

.portfolio-navbar-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: end;
    background: var(--color-footer);
    background-color: white;
    padding: 1rem 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.portfolio-navbar-menu-container p {
    margin: 1rem 0;
}

.portfolio-navbar-menu-container-links-ctabtn {
    display: none;
}

@media screen and (max-width: 1050px) {
    .portfolio-navbar-links-container {
        display: none;
    }

    .portfolio-navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .portfolio-navbar {
        padding: 2rem 4rem;
    }

}

@media screen and (max-width: 550px) {
    .portfolio-navbar {
        padding: 2rem;
    }

    .portfolio-navbar-ctabtn {
        display: none;
    }

    .portfolio-navbar-menu-container {
        top: 20px;
    }

    .portfolio-navbar-menu-container-links-ctabtn {
        display: block;
    }

}