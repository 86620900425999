.service-grid {
    background-color: #05034e;
    width: 250px;
    height: 300px;
    padding: 1.5rem;
    color: #ffffff; 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem;
    position: relative;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 transparent;
}

.service-grid:hover {
    box-shadow: 0 0 20px #d30004;
}

.service-grid-h2 {
    line-height: 29px;
    margin: 0.4rem 0;
    text-align: center;
}

@media screen and (max-width: 790px) {
    .service-grid {
        margin: 1rem;
    }
}

.service-grid h2 {
    line-height: 29px;
    margin: 0.4rem 0;
    text-align: center;
}

.service-grid-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(226, 234, 241);
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.service-grid-icon img {
    width: 50%;
    height: 50%;
}
