@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg,
      #d30004 1.84%, #05034e 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #d30004 -13.86%, #05034e 99.55%);

  --color-bg: #f2f3f5;
  --color-footer: #05274b;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}