.text {
    width: 100%;
    overflow: hidden;
  }
  
  .text-content {
    padding: 10px;
    color: white;
  }
  
  .thead {
    font-weight: bold;
    margin-bottom: 10px;
  }

  