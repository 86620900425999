.services h1{
    font-size: 40px;
    font-weight: 800;
}

.services-container{
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}