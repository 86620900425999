.course {
    padding: 5rem;
    padding-right: 10rem;
    background-image: url('../../assets/coursesbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    align-items: flex-start;
}

.course h1 {
    font-size: 40px;
    font-weight: 800;
}

.course-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #ffffff;
    margin-left: 40rem;
    position: relative;
    margin-bottom: 10rem;
}

.course-heading::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 70px;
    right: -160px;
    border-bottom: 2px solid #c3c1c1;
}

.btn {
    margin-right: 30rem;
}

.btn1 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #007bff;
}

.btn1:hover {
    background-color: #0056b3;
}

.btn2 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #ff0000;
}

.btn2:hover {
    background-color: #b30000;
}

.btn3 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #008000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #008000;
}

.btn3:hover {
    background-color: #0b4e00;
}

.btn4 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #ff1493;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #ff1493;
}

.btn4:hover {
    background-color: rgb(125, 0, 65)
}

.btn5 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #FFA500;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #FFA500;
}

.btn5:hover {
    background-color: #a06902;
}

.btn6 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #964B00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #964B00;
}

.btn6:hover {
    background-color: #703800;
}

.btn7 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #7F00FF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #7F00FF;
}

.btn7:hover {
    background-color: #5102a1;
}

.btn8 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #b5b503;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #b5b503;
}

.btn8:hover {
    background-color: #707001;
}

.btn9 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #cd5c5c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #cd5c5c;
}

.btn9:hover {
    background-color: rgb(152, 80, 92);
}

.btn10 {
    padding: 10px 20px;
    margin: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #db7093;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0px 15px 0px 15px;
    box-shadow: 0px 0px 10px #db7093;
}

.btn10:hover {
    background-color: #a55671;
}

.course {
    text-align: center;
}

@media screen and (max-width: 1050px) {
    .course {
        padding: 10px;
    }

    .course-heading {
        margin-left: 0;
    }
    
    .course-heading::after {
        left: 70px;
        right: 0px;
    }
}

@media screen and (max-width: 768px) {
    .course {
        padding: 10px;
    }

    .course-heading {
        margin-left: 0;
    }
    
    .course-heading::after {
        left: 70px;
        right: 0px;
    }
}

@media screen and (max-width: 480px) {
    .course {
        padding: 0;
    }
}