.life {
    padding: 5rem;
    background-image: url('../../assets/Life At Cadd World/lifebg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.course-logo {
    width: 100px;
    height: auto;
    position: absolute;
    top: 20px;
    left: 20px;
}

.gap {
    height: 7rem;
}

.lifeH {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #000000;
    padding: 0px 120px;
    position: relative;
    background: #ffffff;
    border-radius: 15px 0px 15px 0px;
    white-space: nowrap;
}

.lifecard-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.lifecard {
    width: 100%;
    border: 5px solid rgba(255, 255, 255, 0.9);
    border-radius: 15px 0px 15px 0px;
    overflow: hidden;
}

.lifecard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 800px) {
    .lifecard-container {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .lifeH {
        padding: 0px 20px;
    }
}

@media screen and (max-width: 400px) {
    .lifeH {
        font-size: 12px;
    }
}

@media screen and (max-width: 300px) {
    .lifeH {
        font-size: 10px;
    }
}
