.bim {
    padding: 5rem;
    background-image: url('../../../assets/bimbg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.bim2 {
    padding: 5rem;
    background-image: url('../../../assets/bimbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.bim3 {
    padding: 5rem;
    background-image: url('../../../assets/bimbg3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.civil {
    padding: 5rem;
    background-image: url('../../../assets/civilbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.civil2 {
    padding: 5rem;
    background-image: url('../../../assets/civilbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.infra {
    padding: 5rem;
    background-image: url('../../../assets/infrabg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.visual {
    padding: 5rem;
    background-image: url('../../../assets/visualbg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.visual2 {
    padding: 5rem;
    background-image: url('../../../assets/visualbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.mech {
    padding: 5rem;
    background-image: url('../../../assets/mechbg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.mech2 {
    padding: 5rem;
    background-image: url('../../../assets/mechbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.mep {
    padding: 5rem;
    background-image: url('../../../assets/mepbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.piping {
    padding: 5rem;
    background-image: url('../../../assets/pipingbg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.piping2 {
    padding: 5rem;
    background-image: url('../../../assets/pipingbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.project {
    padding: 5rem;
    background-image: url('../../../assets/projectbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.tool {
    padding: 5rem;
    background-image: url('../../../assets/toolanddiebg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.course-logo {
    width: 100px;
    height: auto;
    position: absolute;
    top: 20px;
    left: 20px;
}

.bimH {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #000000;
    padding: 0px 120px;
    position: relative;
    background: #ffffff;
    border-radius: 15px 0px 15px 0px;
    white-space: nowrap;
}

.bimtext {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
}

.cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.gap {
    height: 7rem;
}

@media screen and (max-width: 800px) {
    .cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .bimH {
        padding: 0px 10px;
        font-size: 10px;
    }
}

@media screen and (max-width: 400px) {
    .bimH {
        font-size: 8px;
    }
}

@media screen and (max-width: 300px) {
    .bimH {
        font-size: 4px;
    }
}