.vision {
    padding: 5rem;
    padding-right: 10rem;
    background-image: url('../../assets/chessmanbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    align-items: flex-start;
    margin-top: 200px;
}

.vision-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    border-radius: 10px;
    margin-left: 45rem;
    border: 3px solid white;
}

.he {
    color: white;
    background-color: #d30004;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    margin-left: 45rem;
}

@media screen and (max-width: 1050px) {
    .vision {
        padding: 0;
    }

    .vision-text {
        margin-left: 0; 
    }

    .he {
        margin-left: 0;
    }
}