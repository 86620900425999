.contact-form{
    display: flex;
    flex-direction: column;
}

.contact-form button{
    background: #d30004;
    height: 56px;
    outline: none;
    border: none;
    border-radius: 5px;
    color:  #fff;
    font-size: 20px;
    cursor: pointer;
    margin-top: 0.75rem;
}

.contact-form-field{
    margin: 0.75rem 0 !important;
}
