.intro {
    padding: 5rem;
    padding-right: 10rem;
    background-image: url('../../assets/introbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    align-items: flex-start;
    margin-top: 200px;
}

.intro-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #000000;
    margin-left: 30rem;
    position: relative;
}

.intro-heading::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 230px;
    right: -160px;
    border-bottom: 2px solid #515050;
}

.intro-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    border-radius: 10px;
    margin-left: 45rem;
}

@media screen and (max-width: 1050px) {
    .intro {
        padding: 0;
    }

    .intro-heading {
        margin-left: 0; 
    }

    .intro-heading::after {
        left: 145px;
        right: 0px;
    }

    .intro-text {
        margin-left: 0; 
    }
}

@media screen and (max-width: 768px) {
    .intro {
        padding: 0;
    }

    .intro-heading {
        margin-left: 0; 
    }

    .intro-heading::after {
        left: 145px;
        right: 0px;
    }

    .intro-text {
        margin-left: 0;  
    }
}

@media screen and (max-width: 480px) {
    .intro {
        padding: 0;
    }

    .intro-heading {
        margin-left: 0; 
    }

    .intro-heading::after {
        left: 145px;
        right: 0px;
    }

    .intro-text {
        margin-left: 0; 
    }
}
