.hero {
    display: flex;
    padding-top: 1rem !important;
}

@media screen and (min-width: 1050px) {
    .hero {
        height: calc(100vh - 100px);
    }
}

.hero-logo {
    width: 300px;
    height: auto;
    position: absolute;
    top: 20px;
    left: 20px;
}

.hero-autos {
    display: flex;
    align-items: center;
}

.hero-auto {
    width: 250px;
    height: auto;
    position: relative;
    margin-top: 10px;
    margin-bottom: 70px;
    margin-right: 10px;
}

.hero-tag {
    font-size: 24px;
}

.hero-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.hero-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 70px;
}

.hero-content h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 45.7px;
    color: #05274b;
    margin-top: -17px;
    letter-spacing: 3px;
}

.hero-content p {
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.explore-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: #d30004;
    border-radius: 5px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0 1rem;
    margin-top: -60px;
    margin-left: -5px;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38%;
}

.hero-image img {
    width: 100%;
    margin-right: 100px;
}

@media screen and (max-width: 1050px) {
    .hero {
        flex-direction: column;
    }

    .hero h1 {
        line-height: 120px;
    }

    .hero-logo {
        width: 200px;
    }

    .hero-auto {
        width: 200px;
    }

    .hero-content {
        margin: 0 0 3rem;
    }

    .hero-image {
        width: 100%;
    }

    .hero-image img {
        margin-right: auto;
    }
}

@media screen and (max-width: 760px) {
    .hero h1 {
        font-size: 48px;
        line-height: 120px;
    }

    .hero-logo {
        width: 200px;
    }

    .hero h2 {
        font-size: 34px;
        margin-top: -14px;
        font-weight: 400;
        letter-spacing: 3.3px;
    }

    .hero p {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-image img {
        margin-right: auto;
    }

    .explore-btn {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-auto {
        width: 160px;
    }

    .gradient-text {
        font-size: 37px !important; 
    }

}

@media screen and (max-width: 400px) {
    .hero h1 {
        font-size: 36px;
        line-height: 120px;
    }

    .hero h2 {
        font-size: 22px;
        margin-top: -3px;
        font-weight: 400;
        letter-spacing: 3.3px;
        line-height: 25px;
    }

    .hero-logo {
        width: 200px;
    }

    .hero p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 1rem;
    }

    .hero-image img {
        margin-right: auto;
    }

    .explore-btn {
        font-size: 15px;
        line-height: 16px;
    }

    .hero-auto {
        width: 140px;
    }

    .gradient-text {
        font-size: 30px !important; 
    }
}

@media screen and (max-width: 281px) {
    .gradient-text {
        font-size: 22px !important; 
    }

    .hero-tag {
        font-size: 12px !important; 
        margin-top: -30px;
    }

    .hero-auto {
        width: 100px;
    }
}

