.choose {
    padding: 5rem;
    padding-right: 10rem;
    background-image: url('../../assets/choosebg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    align-items: flex-start;
    margin-top: 200px;
}

.choose-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #000000;
    margin-left: 30rem;
    position: relative; 
}

.choose-heading::after { 
    content: "";
    position: absolute;
    bottom: -5px; 
    left: 210px;
    right: -160px; 
    border-bottom: 2px solid #515050; 
}

.choose-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: #ffffff; 
    background-color: rgba(0, 0, 0, 0.7); 
    padding: 1rem; 
    border-radius: 10px; 
    margin-left: 45rem;
}

@media screen and (max-width: 1050px) {
    .choose {
        padding: 0;
    }

    .choose-heading {
        margin-left: 0;
    }
    
    .choose-heading::after { 
        left: 120px;
        right: 0px; 
    }
    
    .choose-text {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .choose {
        padding: 0;
    }

    .choose-heading {
        margin-left: 0;
    }
    
    .choose-heading::after { 
        left: 120px;
        right: 0px; 
    }
    
    .choose-text {
        margin-left: 0;
    }
}

@media screen and (max-width: 480px) {
    .choose {
        padding: 0;
    }
}
