.card {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
}

.card-image {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.card-content {
  padding: 10px;
}

.cardhead {
  background-color: #d30004;
  color: white;
  padding: 0px 1px;
  border-radius: 0px 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  margin-top: 10px;
}